import React from "react";
import "./AboutUs.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import understandingclient from "../../Assets/Images/understanding-client.png";
import analysisresearch from "../../Assets/Images/analysis-research.png";
import monitoring from "../../Assets/Images/monitoring.png";
import applicationNameConfig from "../../applicationNameConfig";

const About = () => {
  return (
    <div className="mainWrapper riaMainWrapper">
      <div className="page-wrapper">
        <section className="aboutBanner pt-0">
          <Header />
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Row className="about-banner-caption text-center text-white pb-3">
                  <h1 className="fw-normal">
                    We are an{" "}
                    <span className="fw-bold">
                      Advisory & Wealth Management
                    </span>{" "}
                    Company
                  </h1>
                  <p className="mt-3 mb-0 col-xs-12 col-md-12 col-lg-8 mx-auto">
                    Using a Customer-Centric approach for solutions,{" "}
                    {applicationNameConfig.APPLICATION_NAME} {" "}
                    offers a comprehensive range of expertise in Equity Research
                    and Advisory services (Stocks, Mutual Funds, Bonds),
                    Personal Financial Planning and Structures Products. Our
                    Result Oriented Approach is driven by a three-pronged
                    approach enabled by our robust Digital Technology Platform.
                  </p>
                </Row>
                <Row className="count-section text-center text-white py-4">
                  <Col xs={6} md={6} lg={3} className=" py-1">
                    <i class="bi bi-people"></i>
                    <h4 className="my-1">4,550</h4>
                    <p className="mb-0">Happy Investors</p>
                  </Col>
                  <Col xs={6} md={6} lg={3} className=" py-1">
                    <i class="bi bi-currency-rupee"></i>
                    <h4 className="my-1">245 Cr.</h4>
                    <p className="mb-0">Total Investments</p>
                  </Col>
                  <Col xs={6} md={6} lg={3} className=" py-1">
                    <i class="bi bi-person-check"></i>
                    <h4 className="my-1">100+</h4>
                    <p className="mb-0">Expert Members</p>
                  </Col>
                  <Col xs={6} md={6} lg={3} className=" py-1">
                    <i class="bi bi-buildings"></i>
                    <h4 className="my-1">25</h4>
                    <p className="mb-0">Branches</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="about-section bg-white">
          <Container className="py-4">
            <Row className="py-2 text-center">
              <Col xs={12} md={12} lg={12}>
                <h2 className="title-border mb-2">Why RIA?</h2>
                <p>
                  Our result Oriented approach is driven by three pronged
                  approaches.
                </p>
              </Col>
            </Row>
            <Row className="py-3">
              <Col xs={12} md={12} lg={5} className="my-2">
                <img
                  src={understandingclient}
                  className="rounded"
                  alt="Understanding The Client"
                  title="Understanding The Client"
                />
              </Col>
              <Col xs={12} md={12} lg={7} className="my-1 ps-lg-5">
                <h3 className="mt-3 mb-2">
                  <i class="bi bi-people"></i> |{" "}
                  <span>Understanding The Client</span>
                </h3>
                <p className="mb-0 text-justify">
                  For us, the process begins with an understanding of the
                  client&apos;s behavior and preferences. We believe
                  comprehending a client dramatically improves the odds of
                  successful outcomes. Therefore, we invest considerable time in
                  understanding evolving customer needs. Multiple Personal
                  Interactions to know you better Use of Digital Platform to
                  capture the expectations.
                </p>
              </Col>
            </Row>
            <Row className="py-3  ">
              <Col xs={12} md={12} lg={7} className="my-1 pe-lg-5">
                <h3 className="mt-3 mb-2">
                  <i class="bi bi-book"></i> |{" "}
                  <span>Deep Analysis & Research</span>
                </h3>
                <p className="mb-0 text-justify">
                  Once we grasp customer problems, we deploy a comprehensive
                  range of expertise in process, technology and detailed
                  analysis and research to address them. We look at a mix of
                  human intervention and technology to disrupt the current
                  playing field.
                </p>
              </Col>
              <Col xs={12} md={12} lg={5} className="my-2">
                <img
                  src={analysisresearch}
                  className="rounded"
                  alt="Deep Analysis & Research"
                  title="Deep Analysis & Research"
                  width="100%"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col xs={12} md={12} lg={5} className="my-2">
                <img
                  src={monitoring}
                  className="rounded"
                  alt="Understanding The Client"
                  title="Understanding The Client"
                  width="100%"
                />
              </Col>
              <Col xs={12} md={12} lg={7} className="my-1 ps-lg-5">
                <h3 className="mt-3 mb-2">
                  <i class="bi bi-activity"></i> |{" "}
                  <span>Continuous Monitoring</span>
                </h3>
                <p className="mb-0 text-justify">
                  Regular Meetings with our esteemed clients. Suggest timely
                  corrective actions.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default About;
