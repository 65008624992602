import React from "react";
import { Link, NavLink } from "react-router-dom";
import defaultLogo from "../../Assets/Images/logo.jpg";

const Header = () => {
  return (
    <>
      <header className="header scrolled main-header mainTopHeader ria-header">
        <Link to="/" className="logo text-decoration-none pt-2">
          <div className="d-flex align-items-center logobox">
            <img src={defaultLogo} alt="Logo" title="Logo" height="35px" />
            <span className="logo-text ps-1">RIA</span>
          </div>
        </Link>
        <nav className="navbar-static-top d-flex align-items-center ms-auto">
          <nav className="navbar navbar-expand-lg">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent1"
              aria-controls="navbarSupportedContent1"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="bi bi-list barIcon"></i>
            </button>
            <div
              className="collapse navbar-collapse responsiveView"
              id="navbarSupportedContent1"
            >
              <ul className="navbar-nav me-5 mb-2 mb-lg-0">
                {/* <li>
                  <Link
                    to="/"
                    aria-current="page"
                    class="nav-link active"
                  >
                    <span>Home</span>
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/"
                    aria-current="page"
                    class="nav-link"
                  >
                    <span>Tools</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    aria-current="page"
                    class="nav-link"
                  >
                    <span>Services</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    aria-current="page"
                    class="nav-link"
                  >
                    <span>About Us</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    aria-current="page"
                    class="nav-link"
                  >
                    <span>Insights</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    aria-current="page"
                    class="nav-link"
                  >
                    <span>Compliances</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    aria-current="page"
                    class="nav-link"
                  >
                    <span>Contact Us</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </nav>
        <div className="navbar-custom-menu d-flex align-items-center">
          <div className="headerRightSec p-0">
            <ul className="mb-0">
              <li>
                <ul className="mb-0 d-flex">
                  <li className="nav-item d-flex me-0">
                    <button className="btn btn-primary btn-xs">
                      <NavLink className="btnLink" to={"https://www.finnex.in/register"} target="_blank">
                        Register
                      </NavLink>
                      |
                      <NavLink className="btnLink" to={"https://www.finnex.in/login"} target="_blank">
                        Login
                      </NavLink>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
