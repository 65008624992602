import React from "react";
import "../Home/Home.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Nav,
  Tab,
} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import helpingImg from "../../Assets/Images/helpinghand.jpg";
import testimonialImg1 from "../../Assets/Images/JayChandran.jpg";
import testimonialImg2 from "../../Assets/Images/kiran.jpg";
import Youngadults from "../../Assets/Images/young-adults.jpg";
import Professionals from "../../Assets/Images/professionals.jpg";
import Retirees from "../../Assets/Images/retirees.jpg";
import Hnis from "../../Assets/Images/hnis.jpg";
import applicationNameConfig from "../../applicationNameConfig";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="home riaHome riaMainWrapper mainWrapper">
      <section className="homeBanner position-relative">
        <Header />
        <Carousel fade className="p-3">
          <Carousel.Item className="px-2 slides">
            <Row className="align-items-center">
              <Col xs={12} sm={12} md={12} lg={12} className="pb-4">
                <h1 className="mb-2 fw-normal">
                  Unlock your financial freedom
                </h1>
                <p className="mb-2 text-justify">
                  At {applicationNameConfig.APPLICATION_NAME}, we believe financial freedom is within your reach. Our
                  tailored solutions empower you to achieve your financial goals
                  and live life on your terms. Discover how we can amplify your
                  financial success keeping your priorities in mind.
                </p>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="px-2 slides">
            <Row className="align-items-center">
              <Col xs={12} sm={12} md={12} lg={12} className="pb-4">
                <h1 className="mb-2 fw-normal">
                  Plan your roadmap to financial freedom
                </h1>
                <p className="mb-2 text-justify">
                  Achieve your financial goals faster with a diversified
                  portfolio through strategic asset allocation.
                </p>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </section>
      {/* <section className="solutions-section py-5 text-center" id="offerings">
        <Container>
          <h2 className="mb-2 title-border">
            Solutions designed for your future
          </h2>
          <p className="medium">Specially created financial solutions</p>
          <Row className="mt-2">
            <Col xs={12} sm={12} md={6} lg={4}>
              <div className="featurebox">
                <div className="featureicon d-flex align-items-center justify-content-center mt-2">
                  <i className="bi bi-briefcase"></i>
                </div>
                <h4 className="mb-1 text-center">Financial Planning</h4>
                <div className="perform-overlay p-4">
                  <div className="featuredtls">
                    <p className="text-justify">
                      Financial Planning includes setting financial goals,
                      planning for taxes, building an emergency fund, managing
                      debt, planning for retirement, and creating an estate
                      plan.
                    </p>
                  </div>
                  <div className="know-more">
                    <Link className="btn btn-secondary btn-sm px-3" to="#">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
              <div className="featurebox">
                <div className="featureicon d-flex align-items-center justify-content-center mt-2">
                  <i className="bi bi-bullseye"></i>
                </div>
                <h4 className="mb-1">Goal Planning</h4>
                <div className="perform-overlay p-4">
                  <div className="featuredtls">
                    <p className="text-justify">
                      Goal-based investing solely focuses on your Dreams and
                      Aspirations. It enables you to allocate your hard-earned
                      money to achieve all your short, medium, or long-term
                      goals.
                    </p>
                  </div>
                  <div className="know-more">
                    <Link className="btn btn-secondary btn-sm px-3" to="#">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
              <div className="featurebox">
                <div className="featureicon d-flex align-items-center justify-content-center mt-2">
                  <i className="bi bi-house-door"></i>
                </div>
                <h4 className="mb-1">Retirement Planning</h4>
                <div className="perform-overlay p-4">
                  <div className="featuredtls">
                    <p className="text-justify">
                      We believe in empowering you to enjoy the retirement phase
                      of your life that is free of financial worries or
                      constraints.
                    </p>
                  </div>
                  <div className="know-more">
                    <Link className="btn btn-secondary btn-sm px-3" to="#">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
              <div className="featurebox">
                <div className="featureicon d-flex align-items-center justify-content-center mt-2">
                  <i className="bi bi-mortarboard"></i>
                </div>
                <h4 className="mb-1">Children Education</h4>
                <div className="perform-overlay p-4">
                  <div className="featuredtls">
                    <p className="text-justify">
                      Bless your child with the best education opportunities
                      with financial safety. Your child will be able to fulfil
                      his/her dreams and career goals.
                    </p>
                  </div>
                  <div className="know-more">
                    <Link className="btn btn-secondary btn-sm px-3" to="#">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
              <div className="featurebox">
                <div className="featureicon d-flex align-items-center justify-content-center mt-2">
                  <i className="bi bi-wallet2"></i>
                </div>
                <h4 className="mb-1">Wealth Management</h4>
                <div className="perform-overlay p-4">
                  <div className="featuredtls">
                    <p className="text-justify">
                      Financial Planning includes setting financial goals,
                      planning for taxes, building an emergency fund, managing
                      debt, planning for retirement, and creating an estate
                      plan.
                    </p>
                  </div>
                  <div className="know-more">
                    <Link className="btn btn-secondary btn-sm px-3" to="#">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
              <div className="featurebox">
                <div className="featureicon d-flex align-items-center justify-content-center mt-2">
                  <i className="bi bi-piggy-bank"></i>
                </div>
                <h4 className="mb-1">Investments</h4>
                <div className="perform-overlay p-4">
                  <div className="featuredtls">
                    <p className="text-justify">
                      The goal of investment planning is to strike a balance
                      between risk and reward. An investment advisor can create
                      a diversified portfolio that maximizes potential returns.
                    </p>
                  </div>
                  <div className="know-more">
                    <Link className="btn btn-secondary btn-sm px-3" to="#">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="helping-investment-section text-center py-md-5 py-0">
        <Container className="my-md-3 my-0">
          <Row>
            <Col
              xs={12}
              md={12}
              lg={6}
              className="px-5 py-5 helping-investment-text"
            >
              <p className="mb-4 text-justify">
                At {applicationNameConfig.APPLICATION_NAME}, we don't just
                manage money - we nurture dreams, protect legacies, and empower
                financial success. Whether you're taking your first steps into
                investing, climbing the corporate ladder, approaching
                retirement, or safeguarding generational wealth, our tailored
                strategies evolve with you.
              </p>
              <Button variant="primary" size="lg" className="mb-2 px-3">
                Know more about {applicationNameConfig.APPLICATION_NAME}
              </Button>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={6}
              className="helping-investment-img d-flex align-items-center px-0 py-md-4 py-0"
            >
              <img
                src={helpingImg}
                alt="Helping you optimise your financial situation image"
                width="100%"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4 py-md-5 text-center solutions-section">
        <Container>
          <h2 className="mb-2 title-border">Customized solutions</h2>
          <p className="medium">We provide customized solutions for</p>
          <Row className="py-2 py-md-3">
            <Col xs={12} md={6} lg={3} className="my-1">
              <div className="solutionsCard">
                <div className="topDiv">
                  <img src={Youngadults} alt="Beacon" />
                </div>
                <div className="bottomDiv">
                  <h3>Young Adults</h3>
                  <p className="mt-3 mb-0">Turn your ambitions into assets</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="my-1">
              <div className="ria solutionsCard">
                <div className="topDiv">
                  <img src={Professionals} alt="Beacon" />
                </div>
                <div className="bottomDiv">
                  <h3>Salaried Professionals</h3>
                  <p className="mt-3 mb-0">
                    Balance today's needs with tomorrow's goals
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="my-1">
              <div className="ria solutionsCard">
                <div className="topDiv">
                  <img src={Retirees} alt="Beacon" />
                </div>
                <div className="bottomDiv">
                  <h3>Retirees</h3>
                  <p className="mt-3 mb-0">
                    Transform your life's work into lasting comfort.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="my-1">
              <div className="ria solutionsCard">
                <div className="topDiv">
                  <img src={Hnis} alt="Beacon" />
                </div>
                <div className="bottomDiv">
                  <h3>HNIs</h3>
                  <p className="mt-3 mb-0">
                    Elevate your wealth to leave a powerful legacy.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <div className="my-4 text-center">
            <Button variant="primary" size="lg" className="mb-2 px-3">
              Start your wealth journey now
            </Button>
          </div>
        </Container>
      </section>
      <section className="services-section pt-4 pb-3 py-md-5">
        <Container className="mt-3">
          <div className="text-center">
            <h2 className="mb-2 title-border">Services we offer</h2>
            <p className="medium">Specially created financial services</p>
          </div>
          <div className="services-tabs-wrapper d-flex mt-4 pt-2">
            <ul
              class="nav nav-tabs d-none d-lg-flex col-sm-12 col-md-12 col-lg-4"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#ias-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="ias-tab-pane"
                  aria-selected="true"
                >
                  <i className="bi bi-check2-square me-1"></i> Investment
                  Advisory Services
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="fp-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#fp-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="fp-tab-pane"
                  aria-selected="false"
                >
                  <i className="bi bi-piggy-bank me-1"></i> Financial Planning
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="mfa-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#mfa-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="mfa-tab-pane"
                  aria-selected="false"
                >
                  <i className="bi bi-graph-up me-1"></i> Mutual Fund Advisory
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="era-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#era-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="era-tab-pane"
                  aria-selected="false"
                >
                  <i className="bi bi-bar-chart-line me-1"></i> Equity Research
                  and Advisory
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="rs-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#rs-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="rs-tab-pane"
                  aria-selected="false"
                >
                  <i className="bi bi-shop me-1"></i> Retirement Solutions
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="wm-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#wm-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="wm-tab-pane"
                  aria-selected="false"
                >
                  <i className="bi bi-cash-stack me-1"></i> Wealth Management
                </button>
              </li>
            </ul>
            <div
              class="tab-content accordion col-sm-12 col-md-12 col-lg-8"
              id="servicesTabContent"
            >
              <div
                class="tab-pane fade show active accordion-item"
                id="ias-tab-pane"
                role="tabpanel"
                aria-labelledby="ias-tab"
                tabindex="0"
              >
                <h2 class="accordion-header d-lg-none" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Investment Advisory Services
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show  d-lg-block"
                  aria-labelledby="headingOne"
                  data-bs-parent="#servicesTabContent"
                >
                  <div class="accordion-body">
                    <h3>Investment Advisory Services</h3>
                    <ul>
                      <li>Comprehensive portfolio management</li>
                      <li>Asset allocation strategies</li>
                      <li>Risk Profiling, Assessment and Management</li>
                      <li>Financial Assessment</li>
                      <li>Goals based Planning</li>
                      <li>Retirement Planning</li>
                      <li>Term Insurance Corpus Plan</li>
                      <li>Balance Sheet</li>
                      <li>Tailored Financial Plans</li>
                    </ul>
                    <div className="bgIcon">
                      <i className="bi bi-check2-square"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade accordion-item"
                id="fp-tab-pane"
                role="tabpanel"
                aria-labelledby="fp-tab"
                tabindex="0"
              >
                <h2 class="accordion-header d-lg-none" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Financial Planning
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#servicesTabContent"
                >
                  <div class="accordion-body">
                    <h3>Financial Planning </h3>
                    <ul>
                      <li>Retirement planning</li>
                      <li>Insurance</li>
                      <li>Tax optimization strategies</li>
                      <li>Estate planning and wealth transfer</li>
                    </ul>
                    <div className="bgIcon">
                      <i className="bi bi-piggy-bank"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade accordion-item"
                id="mfa-tab-pane"
                role="tabpanel"
                aria-labelledby="mfa-tab"
                tabindex="0"
              >
                <h2 class="accordion-header d-lg-none" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Mutual Fund Advisory
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingThree"
                  data-bs-parent="#servicesTabContent"
                >
                  <div class="accordion-body">
                    <h3>Mutual Fund Advisory</h3>
                    <ul>
                      <li>Customized mutual fund selection</li>
                      <li>Regular portfolio reviews </li>
                      <li>Performance tracking and reporting</li>
                    </ul>
                    <div className="bgIcon">
                      <i className="bi bi-graph-up"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade accordion-item"
                id="era-tab-pane"
                role="tabpanel"
                aria-labelledby="era-tab"
                tabindex="0"
              >
                <h2 class="accordion-header d-lg-none" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Equity Research and Advisory
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingFour"
                  data-bs-parent="#servicesTabContent"
                >
                  <div class="accordion-body">
                    <h3>Equity Research and Advisory</h3>
                    <ul>
                      <li>In-depth market analysis</li>
                      <li>Stock selection guidance</li>
                      <li>Ongoing market updates</li>
                    </ul>
                    <div className="bgIcon">
                      <i className="bi bi-bar-chart-line"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade accordion-item"
                id="rs-tab-pane"
                role="tabpanel"
                aria-labelledby="rs-tab"
                tabindex="0"
              >
                <h2 class="accordion-header d-lg-none" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Retirement Solutions
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingFive"
                  data-bs-parent="#servicesTabContent"
                >
                  <div class="accordion-body">
                    <h3>Retirement Solutions</h3>
                    <ul>
                      <li>Pension planning</li>
                      <li>Retirement income strategies</li>
                      <li>Long-term care planning</li>
                    </ul>
                    <div className="bgIcon">
                      <i className="bi bi-shop"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade accordion-item"
                id="wm-tab-pane"
                role="tabpanel"
                aria-labelledby="wm-tab"
                tabindex="0"
              >
                <h2 class="accordion-header d-lg-none" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Wealth Management
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingSix"
                  data-bs-parent="#servicesTabContent"
                >
                  <div class="accordion-body">
                    <h3>Wealth Management</h3>
                    <ul>
                      <li>Content</li>
                    </ul>
                    <div className="bgIcon">
                      <i className="bi bi-cash-stack"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="count-section-wrapper py-0 position-relative">
        <Container>
          <Row className="count-section py-3 px-2 px-lg-4">
            <Col
              xs={6}
              md={6}
              lg={3}
              className="pt-2 pb-1 my-2 my-md-0 d-flex align-items-center justify-content-center"
            >
              <i class="bi bi-emoji-smile"></i>
              <div className="ps-3">
                <h4 className="m-0">4,550</h4>
                <p className="mb-0">Happy Investors</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={6}
              lg={3}
              className="pt-2 pb-1 my-2 my-md-0 d-flex align-items-center justify-content-center"
            >
              <i class="bi bi-wallet2"></i>
              <div className="ps-2">
                <h4 className="m-0">245 Cr.</h4>
                <p className="mb-0">Total Investments</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={6}
              lg={3}
              className="pt-2 pb-1 my-2 my-md-0 d-flex align-items-center justify-content-center"
            >
              <i class="bi bi-journal-check"></i>
              <div className="ps-3">
                <h4 className="m-0">100+</h4>
                <p className="mb-0">Expert Members</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={6}
              lg={3}
              className="pt-2 pb-1 my-2 my-md-0 d-flex align-items-center justify-content-center"
            >
              <i class="bi bi-buildings"></i>
              <div className="ps-2">
                <h4 className="m-0">25</h4>
                <p className="mb-0">Branches</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* {testimonialInfo?.length > 0  && <> */}
      <section className="testimonials-section py-4 py-md-5 px-2">
        <Container className="pt-5 pb-3">
          <Row>
            <Col
              xs={12}
              md={5}
              lg={6}
              className="text-center d-flex align-items-center justify-content-center"
            >
              <div>
                <h2 className="mb-2 title-border">Our happy investors</h2>
                <p>Here's what our thrilled clients have to say about Finnex</p>
              </div>
            </Col>
            <Col xs={12} md={7} lg={6} className="overflow-hidden">
              <OwlCarousel
                className="owl-theme pt-4"
                loop
                margin={30}
                items={1}
                center
              >
                <div className="item mb-2">
                  <div className="d-flex">
                    <div className="d-flex align-items-center justify-content-center quote mt-1">
                      <i className="bi bi-quote"></i>
                    </div>
                    <div class="testimonial-card text-start">
                      <div class="testimonial-author d-flex align-items-center mb-3">
                        <div class="author-img me-3">
                          <img src={testimonialImg1} alt="Author Photo" />
                        </div>
                        <div>
                          <h3 class="mb-0">John Snow</h3>
                          <p class="mb-0">CEO</p>
                        </div>
                      </div>
                      <p class="testimonial-content mb-0">
                        I love this platform. Liked the service provided by
                        {applicationNameConfig.APPLICATION_NAME}. It really
                        helped me growing financially. It has made my future
                        safe & secure. WMS has very effective financial products
                        which helps every individual to grow financially.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item mb-2">
                  <div className="d-flex">
                    <div className="d-flex align-items-center justify-content-center quote mt-1">
                      <i className="bi bi-quote"></i>
                    </div>
                    <div class="testimonial-card text-start">
                      <div class="testimonial-author d-flex align-items-center mb-3">
                        <div class="author-img me-3">
                          <img src={testimonialImg2} alt="Author Photo" />
                        </div>
                        <div>
                          <h3 class="mb-0">Kiran Rao</h3>
                          <p class="mb-0">CEO</p>
                        </div>
                      </div>
                      <p class="testimonial-content mb-0">
                        Liked the service provided by{" "}
                        {applicationNameConfig.APPLICATION_NAME}. It really
                        helped me growing financially. It has made my future
                        safe & secure. WMS has very effective financial products
                        which helps every individual to grow financially.
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="start-investment-section py-5" id="focusGoals">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={6} className="py-2">
              {/* <BookAppointment /> */}
            </Col>
            <Col
              xs={12}
              md={12}
              lg={6}
              className="d-flex align-items-center justify-content-center py-5 my-3"
            >
              <div className="text-center">
                <h2 className="mb-2 title-border">Get personal advice</h2>
                <p className="mb-4">When you need it</p>
                <Button variant="primary" size="lg" className="mb-2 px-3">
                  Book a Demo
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
